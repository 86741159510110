import { Show, createSignal } from "solid-js";
import logo from '../../assets/logo.png';

export const Login = () => {

    const [username, setUsername] = createSignal('');
    const [password, setPassword] = createSignal('');
    const [error, setError] = createSignal('');

    const postLogin = async (username: string, password: string) => {

        try {
            const result = await fetch('/authenticate', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    username,
                    password
                })
            });
            const data = await result.json();
            if (data.success) {
                window.location.href = '/';
            } else {
                setError('Wrong email or password');
            }
        } catch (err) {
            setError('Wrong email or password');
        }

    };

    const login = () => {
        if (username() === '' || password() === '') {
            return;
        }
        postLogin(username(), password());
    };

    return (
        <div class="flex items-center p-6">
            <div  class="flex items-center p-6 bg-gray-50 border-4">
                <div class="w-full bg-gray-50">
                    <div class="mb-6 mt-6"><img class="ma-3" src={logo} style={{width: '300px'}}/></div>
                    <div class="grid gap-6 mb-2">
                        <div class="mb-6">
                            <label for="username" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">User</label>
                            <input onChange={(e) => setUsername(e.target.value)} type="text" id="username" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="" required />
                        </div> 
                    </div>

                    <div class="grid gap-6 mb-2">
                        <div class="mb-6">
                            <label for="password" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Password</label>
                            <input onChange={(e) => setPassword(e.target.value)} type="password" id="password" class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="" required />
                        </div> 
                    </div>

                    <Show when={error() !== ''}>
                        <div class="mb-6 bg-red-100 border border-red-400 text-red-700 text-sm px-4 py-3 rounded relative" role="alert">
                            <strong class="font-bold">{ error() } </strong>
                        </div>
                    </Show>

                    <button onClick={ () => login() } type="submit" class="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">Log in</button>
                </div>
            </div>
        </div>
    );
};
